const TakingDecisionsInTheDarkContent = {
    german: {
        date: "17. Juli 2020",
        arrow: "BLOG << ",
        headline: "Taking Decisions in the Dark.",
        first: "On collective curation and our selection criteria.",
        second: "A reflection, done by the nOu-Collective, written by ",
        secondLink: "Carolin Zieringer",
        third: "Only available in English. Long read ahead – but we wanted to make our curation process as transparent as possible, so we hope it’s worth your while!",
        fourthFirst: "As an unresting feminist collective, we are convinced that acknowledging our entanglement(s) with others is one way to overcome traditionally masculinist and colonial, racist ways of thinking. The latter leave our interdependence, our shortcomings, our bounded perspectives in the dark. Consequently, the dark becomes the shelter of the unspoken, the yet-undecided and of that which is unwanted in the brightness/whiteness of what’s considered “normal”.",
        footnote: "[1]",
        fourthSecond: " We, however, are unwilling to pretend these do not exist just because they are made invisible. Nocturnal Unrest is interested in this kind of darkness. From the beginning it was clear that this is where we would have to go, all the way from writing, to curating, to putting the festival on its feet. An indeed did we find ourselves groping in the dark quite a bit.",
        fifth: "Having learnt from intersectional feminist writers like bell hooks, Sara Ahmed, Audre Lorde, Rosi Bradotti, Judith Butler, José Muñoz, Donna Haraway and so many more, we do not think that anything we do is purely the result of an individual’s thought, ever. Rather, we believe in the collective, hence political, nature of knowledge and of our very subjectivity. Where racist hetero-patriarchy puts the spotlight on the autonomous individual, allegedly free from boundaries, without standpoints and thus vulnerabilities, we rather focus on what is going on in the shades surrounding it: where the creatures of our subconscious crawl, where our allies stand and where our friends, colleagues, co-thinkers and co-creators wait to be mentioned, where accidental encounters and long-lasting impressions linger, where the shadow demarcates a standpoint made invisible by e/Enlightenment. Excuse the pun.",
        sixth: "How does one curate collectively? How does one pick and choose specific pieces of art, theory, performance and so on from a huge feminist pool of thought one can barely extract one thing from without also brutally cutting it off? How does one put it in boxes – that of a programme, of a budget, of a set of criteria – without ultimately locking them in there? And how does one make sure a festival’s programme will not simply become the ginormous, narcissistic incarnation of one perspective? These questions were in the back of our heads when curating within our collective. We do not say that we’ve found any definitive answers or that we even managed to live up to the ambitions implied in them. But we tried our best – and we’d like to make it transparent for you to critique, share, comment.",
        thirdHeadlineFirst: "The Selection Committee – Perspective, Knowledge, People",
        seventh: "After we had sent out our Call for Participation (CfP) we started thinking about how we would choose from submissions, in case there were enough. Quite overwhelmingly – and unexpectedly – we got 124 applications from all over the world with topics as diverse as their creators. The collectivity of feminist thought seemed already incarnated in this huge inflow of projects. Obviously, “Nocturnal Unrest” had long been existent and somehow found its way into our minds. Our work did not consist in inventing it – it consisted merely in putting it in words and making space for it.",
        eighth: "Early on, we realized that we very much wanted and needed to extend the selection committee beyond our core team. We do have a diverse background concerning our knowledges (theatre studies, dramaturgy, film studies, cultural anthropology, sociology, political theory, political sciences, gender studies, philosophy) and we’ve made different experiences in the broad field of feminist activism. But as a majority white and cis-female group we were painfully aware of the limitedness of our knowledges in many regards. The way we perceive the world is structured by the way we move through the world and by what society teaches us (not) to know depending on our ascribed sex, gender, race, class, body and other social (un-)markers. Curating collectively thus included working actively on multiperspectivity in our selection committee. This included reducing the influence of our white, cis–perspective. Inviting external members and colleagues to become part of collective curating was therefore grounded in reasons of perspective and identity just as much as it was in reasons of knowledge and expertise that we lacked. We were lucky to be joined by Zandile Darko, Rebecca Gotthilf, Dr. Vanessa E. Thompson and Barbara Luci Carvalho da Fonseca. They’re scholars in post-colonial theory and feminist anthropology, dancers, performer and theatre makers, they’re activists in queer, feminist and Black communities, festival organizers and teachers. Together we decided on the criteria that would guide our readings and discussions of the many projects submitted.",
        thirdHeadlineSecond: "Criterion 1: Subject Matter(s)",
        ninth: "The first one was an easy one – we had thought. Does the project engage intimately with the topic of the Night? Often enough our discussions proved that words are tricky, and that the Night is full of ambivalence. Arguing over the meaning of the Night helped us a great deal in deciding which projects would provide cornerstones of the festival’s dramaturgy from which we advanced to building the whole edifice called programme. What we perhaps spent most time talking about was the question of what it meant exactly to be “engaging” with the Night since the written concept of a workshop can be much more straightforward than the visual material of dance or performance without necessarily being closer to the topic. The same goes for the multifacetedness of feminist discourses. The different backgrounds of our team helped a lot in learning from each other and understanding the diverse references and contexts submissions were entertaining.",
        thirdHeadlineThird: "Criterion 2: (Em)Power",
        tenth: "The emancipatory aspirations or rather the focus on empowerment – be it through direct experience, interchange or knowledge production/exchange – became a second criterion. Therefore, some projects were not chosen even when we were very much convinced by how they worked with “Nocturnal Unrest” (Criteria 1), because we felt that other projects opened more space for festival participants to collectively empower themselves. Thankfully, at this stage we got very valuable input by Vanessa E. Thompson on how to think projects and empowerment in intersectional terms by asking oneself the “right” – better said: the “other/ed” – questions regarding intersectionality and the presences and absences in what is being said. We’d like to share her recommendation for reading, which is a short article named “Beside My Sister, Facing the Enemy: Legal Theory out of Coalition” by Mari J. Matsuda from 1991 as well as an article by Jaamil Olawale Kosoko called “Crisis in the Gallery. Curation as a Praxis of Justice” from 2018 (in the Reader: Blackness&the Postmodern, ed. by Sonya Lindfors).",
        eleventh: "The multiperspectivity of our selection committee helped us to not narrow our understanding of empowerment too much as all of us had different experiences and needs concerning that which we can call empowerment.",
        twelfth: "As well, we were given insightful advice by performer Barbara Luci Carvalho who shared here bifocal experience with selection committees both as a Dancer of colour and member of the collective “Antagon Theatre” as well a festival organizer herself – she is the initiator of “Frauen*Theaterfestival” in Frankfurt (Women’s Theatre Festival Frankfurt). She highlighted the importance of talking and discussion, which was seconded by the dramaturge Marcus Droß from Künstlerhaus Mousonturm. Both insisted on being in conflict about certain projects as being important pointers. When some work gets us discussing, disagreeing, even raging perhaps, it moves us much more than those that we can all agree on from the beginning. As Sara Ahmed writes in The Cultural Politics of Emotion: „Emotions may be crucial in showing us why transformations are so difficult (we remain invested in what we critique), but also how they are possible (our investments move as we move).“ (2004, S. 171-172).",
        thirdHeadlineFourth: "Criterion 3: Emotions matter",
        thirteenth: "That’s why finally our third criterion ended up being an unusual one: We decided that we would also have to trust our subjective impression, whether and how reading abstracts, watching visual material and discussing it with others affected us, and what kinds of feelings it provoked. It’s a shaky criterion, for sure. But it is also one that takes serious the importance of feelings and emotions in feminist work. Far too often, emotionality and subjectivity are feminized and therefore excluded from the rational spheres of decision taking. They remain in the dark as if they didn’t influence those decisions hence being overlooked and deflected. In choosing this criterion we tried to actively reflect on our emotional, subjective and standpoint-related involvements with submitted projects which required a fair deal of trust between each other. It wasn’t easy given that we had to do it all online due to the pandemic. But in hindsight it helped us to grow as a team, to select a programme that we feel will speak to diverse festival audiences while we got a chance to deeply reflect on what drives our work and the related decisions. In fact this might have created quite some internal tensions that will not be relieved immediately. But we do believe that both tension and tenderness need to be granted space in collective work in order to avoid silencing thoughts, feelings and unseen structures.",
        thirdHeadlineFifth: "Drama/turgy",
        fourteenth: "You might have noticed that all criteria but the first were not exactly premised on any a priori curatorial decisions. And that’s on purpose since we decided that collective curation meant, too, that content-wise we’d let ourselves be mainly influenced by the proposals we would get. Everyone who submitted thereby became part of the collective in one way or another. Thus, we did not prescribe a dramaturgy which projects would have to fit in order to be selected but rather it emerged with our progressing readings and discussions. We followed the red threads, the themes and conflicts that emerged over the two months’ time we had given ourselves. Donna Haraway might have thought of a different context when she wrote on the importance of “staying with the trouble” – but that’s exactly what we tried to do. ",
        thirdHeadlineSixth: "On the inevitability of Pragmatism",
        fifteenth: "Of course, there were some pragmatic decisions as well which painfully undermined our idealism. Because in the end, some things just need to get done. To begin with, we were not able to each read 124 submissions while muddling through our non-festival-related lives. Thus, we formed five teams (three of them PoC/white) which consisted each of one expert in the humanities and one expert in the arts. We then split the process up in two rounds which were accompanied by the meta-level discussions described above. In Round 1, every team was assigned a set of 25 proposals they would discuss intensively, and finally put their results in scores (from 0 to 30 points according to the three criteria mentioned). In Round 2, the projects with the highest scores where chosen, while regulating it by help of quota on the formats (that is the “best” four projects from each format category – walks, workshops, talks/lectures, performance/dance, diverse were shortlisted). Because the shortcomings of putting discussions in numbers are painfully clear, every team had the opportunity to shortlist more projects for reasons other than scores (i.e. balancing out themes or standpoints). It was incredibly difficult as we realized that even with this healthy yet somewhat inadequate pragmatism we would have to turn down far more submissions than we wanted to, for reasons of budget, time and capacities.",
        sixteenth: "What followed were even more discussions during which we tried to come up with a programme that balanced different topics, formats, identities and perspectives. Selecting some projects as cornerstones allowed us to build a framework that we would then slowly extend further. They became cornerstones because they felt empowering already when we first read the abstracts, they touched us with their ideas and words and they represented in some way the broad range of feminisms, perspectives, theories, formats and identities that “Nocturnal Unrest” is committed to.",
        thirdHeadlineSeventh: "In hindsight",
        seventeenth: "Still, we remain troubled because of all that could have happened had we chosen a different programme. We remain unresting because our thinking and our dramaturgy are far from being completely inclusive even though we tried our best. We remain groping in the dark because there’s too much none of us knows yet. We remain hopeful, that some of what is amiss will nonetheless emerge during the festival.",
        eighteenth: "We are deeply grateful for the trust put in us by everyone who sent us their work. We deeply value it and we hope that those who will not be presenting at Nocturnal Unrest know that their work is nonetheless an essential part of our collective effort in building feminist worlds. We need to add that we’re convinced that your work has become an important part of collective curation because every single abstract influenced Nocturnal Unrest. None of it was in vain. We hope to meet you in Frankfurt!",
        nineteenth: "Furthermore, we’re planning a panel which will give further space to discuss (collective) curation. We dearly invite everyone interested in asking us questions, bringing forward criticism and discussing the futures of collective/feminist curation with others! Of course, you can also send us critique, questions, input any time via email to info@nocturnal-unrest.de.",
        twentieth: "We’re thankful, exhilarated, and also a little bit proud to soon be able to present our teaser programme that will cover the second half of 2020 and the first half of 2021, as well as the main programme for Nocturnal Unrest which will happen in early summer 2021.",
        twentyfirst: "Thank you for being out there. Stay unresting!",
        twentysecond: " Special thanks to Vanessa E. Thompson who encouraged this line of thought and recommends reading Toni Morison „Playing in the Dark“."
    },
    english: {
        date: "July 17, 2020",
        arrow: "BLOG << ",
        headline: "Taking Decisions in the Dark.",
        first: "On collective curation and our selection criteria.",
        second: "A reflection, done by the nOu-Collective, written by ",
        secondLink: "Carolin Zieringer",
        third: "Only available in English. Long read ahead – but we wanted to make our curation process as transparent as possible, so we hope it’s worth your while!",
        fourthFirst: "As an unresting feminist collective, we are convinced that acknowledging our entanglement(s) with others is one way to overcome traditionally masculinist and colonial, racist ways of thinking. The latter leave our interdependence, our shortcomings, our bounded perspectives in the dark. Consequently, the dark becomes the shelter of the unspoken, the yet-undecided and of that which is unwanted in the brightness/whiteness of what’s considered “normal”.",
        footnote: "[1]",
        fourthSecond: " We, however, are unwilling to pretend these do not exist just because they are made invisible. Nocturnal Unrest is interested in this kind of darkness. From the beginning it was clear that this is where we would have to go, all the way from writing, to curating, to putting the festival on its feet. An indeed did we find ourselves groping in the dark quite a bit.",
        fifth: "Having learnt from intersectional feminist writers like bell hooks, Sara Ahmed, Audre Lorde, Rosi Bradotti, Judith Butler, José Muñoz, Donna Haraway and so many more, we do not think that anything we do is purely the result of an individual’s thought, ever. Rather, we believe in the collective, hence political, nature of knowledge and of our very subjectivity. Where racist hetero-patriarchy puts the spotlight on the autonomous individual, allegedly free from boundaries, without standpoints and thus vulnerabilities, we rather focus on what is going on in the shades surrounding it: where the creatures of our subconscious crawl, where our allies stand and where our friends, colleagues, co-thinkers and co-creators wait to be mentioned, where accidental encounters and long-lasting impressions linger, where the shadow demarcates a standpoint made invisible by e/Enlightenment. Excuse the pun.",
        sixth: "How does one curate collectively? How does one pick and choose specific pieces of art, theory, performance and so on from a huge feminist pool of thought one can barely extract one thing from without also brutally cutting it off? How does one put it in boxes – that of a programme, of a budget, of a set of criteria – without ultimately locking them in there? And how does one make sure a festival’s programme will not simply become the ginormous, narcissistic incarnation of one perspective? These questions were in the back of our heads when curating within our collective. We do not say that we’ve found any definitive answers or that we even managed to live up to the ambitions implied in them. But we tried our best – and we’d like to make it transparent for you to critique, share, comment.",
        thirdHeadlineFirst: "The Selection Committee – Perspective, Knowledge, People",
        seventh: "After we had sent out our Call for Participation (CfP) we started thinking about how we would choose from submissions, in case there were enough. Quite overwhelmingly – and unexpectedly – we got 124 applications from all over the world with topics as diverse as their creators. The collectivity of feminist thought seemed already incarnated in this huge inflow of projects. Obviously, “Nocturnal Unrest” had long been existent and somehow found its way into our minds. Our work did not consist in inventing it – it consisted merely in putting it in words and making space for it.",
        eighth: "Early on, we realized that we very much wanted and needed to extend the selection committee beyond our core team. We do have a diverse background concerning our knowledges (theatre studies, dramaturgy, film studies, cultural anthropology, sociology, political theory, political sciences, gender studies, philosophy) and we’ve made different experiences in the broad field of feminist activism. But as a majority white and cis-female group we were painfully aware of the limitedness of our knowledges in many regards. The way we perceive the world is structured by the way we move through the world and by what society teaches us (not) to know depending on our ascribed sex, gender, race, class, body and other social (un-)markers. Curating collectively thus included working actively on multiperspectivity in our selection committee. This included reducing the influence of our white, cis–perspective. Inviting external members and colleagues to become part of collective curating was therefore grounded in reasons of perspective and identity just as much as it was in reasons of knowledge and expertise that we lacked. We were lucky to be joined by Zandile Darko, Rebecca Gotthilf, Dr. Vanessa E. Thompson and Barbara Luci Carvalho da Fonseca. They’re scholars in post-colonial theory and feminist anthropology, dancers, performer and theatre makers, they’re activists in queer, feminist and Black communities, festival organizers and teachers. Together we decided on the criteria that would guide our readings and discussions of the many projects submitted.",
        thirdHeadlineSecond: "Criterion 1: Subject Matter(s)",
        ninth: "The first one was an easy one – we had thought. Does the project engage intimately with the topic of the Night? Often enough our discussions proved that words are tricky, and that the Night is full of ambivalence. Arguing over the meaning of the Night helped us a great deal in deciding which projects would provide cornerstones of the festival’s dramaturgy from which we advanced to building the whole edifice called programme. What we perhaps spent most time talking about was the question of what it meant exactly to be “engaging” with the Night since the written concept of a workshop can be much more straightforward than the visual material of dance or performance without necessarily being closer to the topic. The same goes for the multifacetedness of feminist discourses. The different backgrounds of our team helped a lot in learning from each other and understanding the diverse references and contexts submissions were entertaining.",
        thirdHeadlineThird: "Criterion 2: (Em)Power",
        tenth: "The emancipatory aspirations or rather the focus on empowerment – be it through direct experience, interchange or knowledge production/exchange – became a second criterion. Therefore, some projects were not chosen even when we were very much convinced by how they worked with “Nocturnal Unrest” (Criteria 1), because we felt that other projects opened more space for festival participants to collectively empower themselves. Thankfully, at this stage we got very valuable input by Vanessa E. Thompson on how to think projects and empowerment in intersectional terms by asking oneself the “right” – better said: the “other/ed” – questions regarding intersectionality and the presences and absences in what is being said. We’d like to share her recommendation for reading, which is a short article named “Beside My Sister, Facing the Enemy: Legal Theory out of Coalition” by Mari J. Matsuda from 1991 as well as an article by Jaamil Olawale Kosoko called “Crisis in the Gallery. Curation as a Praxis of Justice” from 2018 (in the Reader: Blackness&the Postmodern, ed. by Sonya Lindfors).",
        eleventh: "The multiperspectivity of our selection committee helped us to not narrow our understanding of empowerment too much as all of us had different experiences and needs concerning that which we can call empowerment.",
        twelfth: "As well, we were given insightful advice by performer Barbara Luci Carvalho who shared here bifocal experience with selection committees both as a Dancer of colour and member of the collective “Antagon Theatre” as well a festival organizer herself – she is the initiator of “Frauen*Theaterfestival” in Frankfurt (Women’s Theatre Festival Frankfurt). She highlighted the importance of talking and discussion, which was seconded by the dramaturge Marcus Droß from Künstlerhaus Mousonturm. Both insisted on being in conflict about certain projects as being important pointers. When some work gets us discussing, disagreeing, even raging perhaps, it moves us much more than those that we can all agree on from the beginning. As Sara Ahmed writes in The Cultural Politics of Emotion: „Emotions may be crucial in showing us why transformations are so difficult (we remain invested in what we critique), but also how they are possible (our investments move as we move).“ (2004, S. 171-172).",
        thirdHeadlineFourth: "Criterion 3: Emotions matter",
        thirteenth: "That’s why finally our third criterion ended up being an unusual one: We decided that we would also have to trust our subjective impression, whether and how reading abstracts, watching visual material and discussing it with others affected us, and what kinds of feelings it provoked. It’s a shaky criterion, for sure. But it is also one that takes serious the importance of feelings and emotions in feminist work. Far too often, emotionality and subjectivity are feminized and therefore excluded from the rational spheres of decision taking. They remain in the dark as if they didn’t influence those decisions hence being overlooked and deflected. In choosing this criterion we tried to actively reflect on our emotional, subjective and standpoint-related involvements with submitted projects which required a fair deal of trust between each other. It wasn’t easy given that we had to do it all online due to the pandemic. But in hindsight it helped us to grow as a team, to select a programme that we feel will speak to diverse festival audiences while we got a chance to deeply reflect on what drives our work and the related decisions. In fact this might have created quite some internal tensions that will not be relieved immediately. But we do believe that both tension and tenderness need to be granted space in collective work in order to avoid silencing thoughts, feelings and unseen structures.",
        thirdHeadlineFifth: "Drama/turgy",
        fourteenth: "You might have noticed that all criteria but the first were not exactly premised on any a priori curatorial decisions. And that’s on purpose since we decided that collective curation meant, too, that content-wise we’d let ourselves be mainly influenced by the proposals we would get. Everyone who submitted thereby became part of the collective in one way or another. Thus, we did not prescribe a dramaturgy which projects would have to fit in order to be selected but rather it emerged with our progressing readings and discussions. We followed the red threads, the themes and conflicts that emerged over the two months’ time we had given ourselves. Donna Haraway might have thought of a different context when she wrote on the importance of “staying with the trouble” – but that’s exactly what we tried to do. ",
        thirdHeadlineSixth: "On the inevitability of Pragmatism",
        fifteenth: "Of course, there were some pragmatic decisions as well which painfully undermined our idealism. Because in the end, some things just need to get done. To begin with, we were not able to each read 124 submissions while muddling through our non-festival-related lives. Thus, we formed five teams (three of them PoC/white) which consisted each of one expert in the humanities and one expert in the arts. We then split the process up in two rounds which were accompanied by the meta-level discussions described above. In Round 1, every team was assigned a set of 25 proposals they would discuss intensively, and finally put their results in scores (from 0 to 30 points according to the three criteria mentioned). In Round 2, the projects with the highest scores where chosen, while regulating it by help of quota on the formats (that is the “best” four projects from each format category – walks, workshops, talks/lectures, performance/dance, diverse were shortlisted). Because the shortcomings of putting discussions in numbers are painfully clear, every team had the opportunity to shortlist more projects for reasons other than scores (i.e. balancing out themes or standpoints). It was incredibly difficult as we realized that even with this healthy yet somewhat inadequate pragmatism we would have to turn down far more submissions than we wanted to, for reasons of budget, time and capacities.",
        sixteenth: "What followed were even more discussions during which we tried to come up with a programme that balanced different topics, formats, identities and perspectives. Selecting some projects as cornerstones allowed us to build a framework that we would then slowly extend further. They became cornerstones because they felt empowering already when we first read the abstracts, they touched us with their ideas and words and they represented in some way the broad range of feminisms, perspectives, theories, formats and identities that “Nocturnal Unrest” is committed to.",
        thirdHeadlineSeventh: "In hindsight",
        seventeenth: "Still, we remain troubled because of all that could have happened had we chosen a different programme. We remain unresting because our thinking and our dramaturgy are far from being completely inclusive even though we tried our best. We remain groping in the dark because there’s too much none of us knows yet. We remain hopeful, that some of what is amiss will nonetheless emerge during the festival.",
        eighteenth: "We are deeply grateful for the trust put in us by everyone who sent us their work. We deeply value it and we hope that those who will not be presenting at Nocturnal Unrest know that their work is nonetheless an essential part of our collective effort in building feminist worlds. We need to add that we’re convinced that your work has become an important part of collective curation because every single abstract influenced Nocturnal Unrest. None of it was in vain. We hope to meet you in Frankfurt!",
        nineteenth: "Furthermore, we’re planning a panel which will give further space to discuss (collective) curation. We dearly invite everyone interested in asking us questions, bringing forward criticism and discussing the futures of collective/feminist curation with others! Of course, you can also send us critique, questions, input any time via email to info@nocturnal-unrest.de.",
        twentieth: "We’re thankful, exhilarated, and also a little bit proud to soon be able to present our teaser programme that will cover the second half of 2020 and the first half of 2021, as well as the main programme for Nocturnal Unrest which will happen in early summer 2021.",
        twentyfirst: "Thank you for being out there. Stay unresting!",
        twentysecond: " Special thanks to Vanessa E. Thompson who encouraged this line of thought and recommends reading Toni Morison „Playing in the Dark“."
    
    }
}
export {TakingDecisionsInTheDarkContent}