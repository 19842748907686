import React, { useContext } from "react"
import { Link } from "gatsby"
import Template from "../../components/template"
import "../../styles/blog/blogpost.css"
import { TakingDecisionsInTheDarkContent } from "../../content/blog/taking-decisions-in-the-dark"
import { SettingsContext } from "../../contexts/settings"

const TakingDecisionsInTheDarkPage = () => {
    const [settings, ] = useContext(SettingsContext)
    const content = TakingDecisionsInTheDarkContent[settings.language]
    return (
        <Template currentPage="blog">
            <section>
            <div>
                <h1>
                    <Link to="/blog#taking-decisions-in-the-dark" class="back-arrow">{content.arrow}</Link>
                    {content.headline}
                </h1>
                <p>{content.date}</p>
                <p>
                    <b>{content.first}</b>
                </p>
                <p>
                    <b>
                        {content.second}
                        <Link className="link-bold" to="/team">{content.secondLink}</Link>
                    </b>
                </p>
                <p>
                    {content.third}
                </p>
                <p>
                    {content.fourthFirst}
                    <Link className="link-text-no-decoration" to="#footnote-2">&sup1;</Link>
                    {content.fourthSecond}
                </p>
                <p>{content.fifth}</p>
                <p>{content.sixth}</p>
                <h4>{content.thirdHeadlineFirst}</h4>
                <p>{content.seventh}</p>
                <p>{content.eighth}</p>
                <h4>{content.thirdHeadlineSecond}</h4>
                <p>{content.ninth}</p>
                <h4>{content.thirdHeadlineThird}</h4>
                <p>{content.tenth}</p>
                <p>{content.eleventh}</p>
                <p>{content.twelfth}</p>
                <h4>{content.thirdHeadlineFourth}</h4>
                <p>{content.thirteenth}</p>
                <h4>{content.thirdHeadlineFifth}</h4>
                <p>{content.fourteenth}</p>
                <h4>{content.thirdHeadlineSixth}</h4>
                <p>{content.fifteenth}</p>
                <p>{content.sixteenth}</p>
                <h4>{content.thirdHeadlineSeventh}</h4>
                <p>{content.seventeenth}</p>
                <p>{content.eighteenth}</p>
                <p>{content.nineteenth}</p>
                <p>{content.twentieth}</p>
                <p>{content.twentyfirst}</p>
                <div className="white-line"></div>
                <p className="end-of-site text-middle"id="footnote-2">&sup1; {content.twentysecond}</p>
            </div>    
            </section>
        </Template >
    )
}

export default TakingDecisionsInTheDarkPage